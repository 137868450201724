import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/layout"
import HeaderSecondary from "../components/headerSecondary"
import Footer from "../components/footer"
import SEO from "../components/seo"
import BookStrategyBtn from "../components/BookStrategyBtn"
import ReadyStarted from "../components/ReadyStarted"
import MainNav from "../components/MainNav"

import { FaAngleDoubleRight } from "react-icons/fa"

export const getOurTeamData = graphql`
  {
    aboutImg: file(relativePath: { eq: "about_team.jpg" }) {
      size
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    wpgraphql_greatweb {
      pageBy(uri: "why-us") {
        pageSiteMetaTitle_acf {
          sitemetatitle
        }
        pageSiteMetaDescription_acf {
          siteMetaDescription
        }
      }
      homePageBy: pageBy(uri: "home") {
        home_pagesection_acf {
          sections {
            generalSettings {
              announcement
              countdownData
              companyAddress
              companyContactNumber
              companyOverview
              facebookLink
              linkedinLink
              supportEmail
              twiiterLink
            }
          }
        }
      }
      ourTeam {
        nodes {
          team_acf {
            name
            position
            photo {
              sourceUrl
            }
          }
        }
      }
    }
  }
`

const about = ({ data, active }) => {
  const {
    aboutImg: {
      childImageSharp: { fluid },
    },
    wpgraphql_greatweb: {
      pageBy: {
        pageSiteMetaTitle_acf: { sitemetatitle },
        pageSiteMetaDescription_acf: { siteMetaDescription },
      },
      homePageBy: {
        home_pagesection_acf: {
          sections: {
            generalSettings: {
              announcement,
              countdownData,
              companyAddress,
              companyContactNumber,
              companyOverview,
              facebookLink,
              linkedinLink,
              supportEmail,
              twiiterLink,
            },
          },
        },
      },
      ourTeam: { nodes: teams },
    },
  } = data

  return (
    <Layout
      announcement={!announcement ? "" : announcement}
      countdownData={!countdownData ? "" : countdownData}
    >
      <SEO title={sitemetatitle} description={siteMetaDescription} />
      <MainNav active="about" />
      <HeaderSecondary
        // siteTitle={data.site.siteMetadata?.title || `GreatWeb`}
        active="about"
        heading="Why Us?"
        subheading="It's great to have a pretty website, but it;s even better if that website is bringing in more money right?"
        // background={heroBG}
        // contact={contactNumber}
      />
      <section className="about container">
        <div className="about__col">
          <div className="about__left">
            <div className="about__imgWrapper">
              {/* <img
              className="about__img"
              src={require("../images/about.png")}
              alt="About GreatWeb"
            /> */}

              <Image
                className="about__img"
                fluid={fluid}
                alt="About Great Web"
              />
            </div>
          </div>
          <div className="about__right">
            <h2 className="about__heading section-title">
              We Build More Than Just a Website
            </h2>
            <p className="about__subheading">
              Let’s not beat around the bush...You’re on this website because
              you’re hungry to grow your business...
            </p>
            <p className="about__subheading">
              Great Web is specializing in local business enhancement. We offer
              measurable revenue growth through website design, company
              branding, online reviews, local SEO and more. We are more than
              just a design firm. We set ourselves apart with years of sales
              experience coupled with extensive design and marketing expertise.
              Everything we create is optimized to generate more customers, more
              sales, and higher revenue.
            </p>
          </div>
        </div>

        <div className="about__questions container">
          <h3 className="about__questions--heading">
            If you have ever asked any of the questions below you are in the
            right place...
          </h3>
          <ul className="about__questions--list">
            <li className="about__questions--item">
              <div>
                <FaAngleDoubleRight className="about__questions--item--span" />
              </div>
              <span>
                {" "}
                How can I get customers for my local business from the Internet?
              </span>
            </li>
            <li className="about__questions--item">
              <div>
                <FaAngleDoubleRight className="about__questions--item--span" />
              </div>
              <span>
                {" "}
                How can I compete with bigger companies online with a smaller
                budget?
              </span>
            </li>
            <li className="about__questions--item">
              <div>
                <FaAngleDoubleRight className="about__questions--item--span" />
              </div>
              <span>
                {" "}
                Why isn’t my website generating sales (or even leads)?
              </span>
            </li>
            <li className="about__questions--item">
              <div>
                <FaAngleDoubleRight className="about__questions--item--span" />
              </div>
              <span>
                {" "}
                What does my website need to have on it to convert site
                visitors?
              </span>
            </li>
            <li className="about__questions--item">
              <div>
                <FaAngleDoubleRight className="about__questions--item--span" />
              </div>
              <span> Is a website really necessary or worth the money?</span>
            </li>
          </ul>
        </div>
      </section>

      <div className="team container">
        <div className="team__wrapper">
          <h2 className="team__heading section-title">
            The Team You Will Trust That Gives You Result Behind the Scene
          </h2>
        </div>

        <ul className="team__list list-unstyled">
          {teams.map((item, index) => (
            <li key={index} className="team__item">
              <div className="team__imgWrapper">
                <img
                  src={item.team_acf.photo.sourceUrl}
                  className="team__img"
                  alt="Great Web Team"
                />
              </div>
              <h3 className="team__name">{item.team_acf.name}</h3>
              <p className="team__position">{item.team_acf.position}</p>
            </li>
          ))}
        </ul>
        <ReadyStarted />
        <BookStrategyBtn />
        <Footer
          companyAddress={companyAddress}
          companyContactNumber={companyContactNumber}
          companyOverview={companyOverview}
          facebookLink={facebookLink}
          linkedinLink={linkedinLink}
          supportEmail={supportEmail}
          twiiterLink={twiiterLink}
        />
      </div>
    </Layout>
  )
}

export default about
